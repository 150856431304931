<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
<!--              <b-form-input-->
<!--                  v-model="searchKey"-->
<!--                  class="d-inline-block mr-1"-->
<!--                  placeholder="搜索关键字..."-->
<!--              />-->
              <!--              <b-button-->
              <!--                variant="primary"-->
              <!--                :to="{ name: 'apps-stockstatuschange-edit'}"-->
              <!--              >-->
              <!--                <span class="text-nowrap">添加</span>-->
              <!--              </b-button>-->
            </div>
          </b-col>
        </b-row>

      </div>

      <xy-tab
          :tabs="getCodeOptions('stock_status_change')"
          :statusValue="status"
          v-on:emitTabs="function($event) {tabStatus = $event}"
      ></xy-tab>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <!-- Column: Type -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('stock_status_change', data.item.status)}`"
          >
            {{ getCodeLabel('stock_status_change', data.item.status) }}
          </b-badge>
        </template>

        <template #cell(reason)="data">
          <div>
            {{ data.item.reason.substring(0, 3) }}
            <feather-icon
                v-if="data.item.reason.length > 3"
                icon="EyeIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+data.item.reason+
              '</div>'"
                variant="success"
            />
          </div>
        </template>

        <template #cell(name)="data">
          {{ data.item.ext.name }}
          <feather-icon
              icon="CpuIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="success"
              @click="function () {
                      stockOccupied.warehouse_id=data.item.warehouse_id
                      stockOccupied.stock_id=data.item.stock_id
                      $refs['modal-stock-lock'].show()
                      $forceUpdate()
                      }"
          />
        </template>

        <template #cell(goodstock)="data">
          {{ getCodeLabel('warehouse_location',data.item.ext.stockLocation) }} | {{getCodeLabel('stock_status',data.item.ext.stock.status)}} | {{data.item.stock_qty}}
        </template>

        <template #cell(badstock)="data">
          {{ getCodeLabel('warehouse_location',data.item.ext.goodLocation) }} | 良品 | {{data.item.goodstock}} +
          {{ getCodeLabel('warehouse_location',data.item.ext.badLocation) }} | 不良品 | {{data.item.badstock}}
        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <template #cell(modify_time)="data">
          {{ toDate(data.item.modify_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(updator)="data">
          {{ getCodeLabel('user', data.item.updator) }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="changeStatus(data.item,2,'提交审核')" v-if="data.item.status == 1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,3,'审核通过')" v-if="data.item.status == 2&&[14,17,18,5,6].includes(user.role_id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">审核通过</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,4,'审核失败')" v-if="data.item.status == 2&&[14,17,18,5,6].includes(user.role_id)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">审核失败</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="[3,4].includes(data.item.status)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">无菜单</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
        id="modal-stock-lock"
        ref="modal-stock-lock"
        no-close-on-backdrop
        cancel-title="取消"
        centered
        size="xl"
        title="锁库单据"
    >
      <stock-occupied-list
          :warehouse_id="stockOccupied.warehouse_id"
          :stock_id="stockOccupied.stock_id"
      >

      </stock-occupied-list>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import stockstatuschangeUseList from './stockstatuschangeUseList'
import stockstatuschangeStore from './stockstatuschangeStore'
import {getUserData} from "@/auth/utils";
import {useToast} from "vue-toastification/composition";
import XyTab from "@/views/components/xy/XyTab";
import Ripple from "vue-ripple-directive";
import StockOccupiedList from "@/views/apps/stockoccupied/StockOccupiedList.vue";

export default {
  components: {
    StockOccupiedList,
    XyTab,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data(){
    return{
      status:2,
      user:{},
      stockOccupied:{},
    }
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('stockstatuschange/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('stockstatuschange')) store.registerModule('stockstatuschange', stockstatuschangeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stockstatuschange')) store.unregisterModule('stockstatuschange')
    })

    const changeStatus = function (params, status, information) {
      if (confirm("是否" + information)) {
        store.dispatch('stockstatuschange/changeStatus', {id: params.change_id, status: status}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      tabStatus,

      // UI
    } = stockstatuschangeUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      tabStatus,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      isEmpty,
      changeStatus,
    }
  },
  created() {
    this.user = getUserData()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
